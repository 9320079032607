import React from "react"
import Layout from "../components/Layout" 
import { graphql, Link } from "gatsby"

import img from "../images/agenda/agenda_main.jpg"

const WorkPage = ({ data }) => {

  return (
    <>
      <Layout>
        <div className="page-wrapper agenda">
          <div className="main-image">
            <img src={img}/>
          </div>

          <h1>AGENDA</h1>
          
          <div className="list-agenda-header">
            <h2>Le roi est mort</h2>
            <h3>Création</h3>
          </div>

          <div className="list-agenda">
            <div className="list-agenda-item ended">
                <h3>Le 02/12/2023 à 20h30</h3>
                <h4>Alfortville</h4>
                <h5><a href="https://goo.gl/maps/y2CJuwo59dHpEr647" target="_blank" rel="noopener">Le POC</a></h5>
                <a href="/contact">Réserver</a>
            </div>
          </div>
          
          <div className="list-agenda-header">
            <h2>Le roi est mort - Répétition ouverte</h2>
            <h3>Répétition</h3>
          </div>

          <div className="list-agenda">
            <div className="list-agenda-item ended">
                <h3>Le 26/10/2023 de 15h00 à 16h00</h3>
                <h4>Alfortville</h4>
                <h5><a href="https://goo.gl/maps/y2CJuwo59dHpEr647" target="_blank" rel="noopener">Le POC</a></h5>
                <a href="/contact">Réserver</a>
            </div>
          </div>
          
          <div>
              <Link className="button center" to="/contact">Contact</Link>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default WorkPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
